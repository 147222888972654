import axios, { Axios } from "axios";
import { useRef } from "react";
import { API_URL } from "src/core/constants";

export const useUnauthenticatedHttpClient = () => {
    const axiosRef = useRef<Axios>();

    if (!axiosRef.current) {
        axiosRef.current = axios.create({
            baseURL: API_URL,
        });
    }

    return axiosRef.current;
};
